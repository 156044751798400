import * as actionTypes from './actionTypes';

const initialState = {
    userInfo: null,
    lang: "tr"
};

export default function store(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_USER_INFO:
            return {
                ...state,
                userInfo: action.payload
            }

        case actionTypes.UPDATE_LANGUAGE:
            return {
                ...state,
                lang: action.payload
            }
        default:
            return state;
    }
};