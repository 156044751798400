import React, { Suspense, lazy, useEffect, useReducer, useState } from 'react'
import axios from 'axios';

import { Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUserInfo } from './store/actions';
import Loading from './components/common/Loading/Loading';
import NotFound from './components/common/NotFound/NotFound';

const LoginLazy = lazy(() => import("./pages/Login/Login"))
const PasswordRememberLazy = lazy(() => import("./pages/Login/PasswordRemember"));

function Auth({ children }) {
  // const getUserInfo = async () => {
  //   return await axios.get("/authentication/loggedIn")
  //     .then((response) => response.data)
  //     .catch((err) =>{
  //        return false
  //       }
  //     )
  // }

  const [content, setContent] = useState();
  const dispatch = useDispatch();

  const getLoggedIn = async () => {
    setContent(<Loading />)
    await axios.get("/authentication/loggedIn")
      .then((response) => {
        dispatch(setUserInfo(response.data))
        setContent(children)
      })
      .catch(() => {
        setContent(<>
          <Routes>
            <Route path="/login" element={<LoginLazy />} />
            <Route path="/password-remember" element={<PasswordRememberLazy />} />
            <Route path='*' element={<NotFound url="/login" status="isNotAuth" />} />
          </Routes>
        </>)
      })
  }


  useEffect(() => {
    getLoggedIn();
  }, []);

  return (
    <Suspense fallback={<Loading />}>{content}</Suspense>
  )
};

export default Auth;