import AppRoutes from './AppRoutes';
import Auth from './Auth';

function App() {
  return (
    <>
      <Auth>
        <AppRoutes />
      </Auth>
    </>
  );
}

export default App;
