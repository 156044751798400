import * as actionTypes from './actionTypes';

export const setUserInfo = userInfo => ({
    type: actionTypes.SET_USER_INFO,
    payload: userInfo
});

export const updateLanguage = lang => ({
    type: actionTypes.UPDATE_LANGUAGE,
    payload: lang
});