import React, { useEffect } from 'react'
import { types } from '../../../Translation/tr_translation';
import { useSelector } from 'react-redux';

function NotFound({url, status}) {
    const lang = useSelector(state => state.lang);

    useEffect(() => {
        setTimeout(() => {
            window.location.replace(url)
        }, 5000)

    }, [url]);
    return (
        <div style={{textAlign: "center", fontSize: "25px", paddingTop: "20px"}}>
            {types[lang].notFound[status]}
        </div>
    )
}
export default NotFound;