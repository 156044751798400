import { Spin } from 'antd';
import React from 'react'
import "./Loading.scss";

function Loading() {
  return (
    <div className='loading'>
          <Spin />
    </div>
  )
}
export default Loading;