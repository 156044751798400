import React, { Suspense, lazy } from 'react'
import { Route, Routes } from 'react-router-dom';
import NotFound from './components/common/NotFound/NotFound';
import { useSelector } from 'react-redux';
import Loading from './components/common/Loading/Loading';

const PageStructureLazy = lazy(() => import('./components/common/PageStructure/PageStructure'))
const HomePageLazy = lazy(() => import("./pages/Homepage/Homepage"));
const MyInformationLazy = lazy(() => import("./pages/MyInformation/MyInformation"));
const ChangePasswordLazy = lazy(() => import("./pages/MyInformation/ChangePassword"));
const UserListLazy = lazy(() => import("./pages/Users/UserList"));
const UserDetailLazy = lazy(() => import("./pages/Users/UserDetail"));
const UserUpdateLazy = lazy(() => import("./pages/Users/UserUpdate"));
const AddUserLazy = lazy(() => import("./pages/Users/AddUser"));
const LoginLazy = lazy(() => import("./pages/Login/Login"))
const PasswordRememberLazy = lazy(() => import("./pages/Login/PasswordRemember"));
const ComingPageLazy = lazy(() => import("./components/common/Coming/ComingPage"));

export const ProtectedRoute = ({ role, children }) => {
    const userInfo = useSelector(state => state.userInfo);
    return userInfo.authority === role && children
}

function AppRoutes() {
    return (
        <>
            <Routes>
                <Route path="/" element={<PageStructureLazy />} >
                    <Route path="" element={<HomePageLazy />} />
                    <Route path="my-information" element={<Suspense fallback={<Loading />} >
                        <MyInformationLazy />
                    </Suspense>} />
                    <Route path="password-change" element={
                        <Suspense fallback={<Loading />} >
                            <ChangePasswordLazy />
                        </Suspense>} />

                    <Route path="users" element={<Suspense fallback={<Loading />} >
                        <ProtectedRoute role="manager">
                            <UserListLazy />
                        </ProtectedRoute>
                    </Suspense>} />

                    <Route path="user/detail/:userId" element={<Suspense fallback={<Loading />} >
                        <ProtectedRoute role="manager">
                            <UserDetailLazy />
                        </ProtectedRoute>
                    </Suspense>} />
                    <Route path="user/update/:userId" element={<Suspense fallback={<Loading />} >
                        <ProtectedRoute role="manager">
                            <UserUpdateLazy />
                        </ProtectedRoute>
                    </Suspense>} />
                    <Route path="user/add" element={<Suspense fallback={<Loading />} >
                        <ProtectedRoute role="manager">
                            <AddUserLazy />
                        </ProtectedRoute>
                    </Suspense>} />
                    <Route path="graphic" element={<Suspense fallback={<Loading />} >
                        <ProtectedRoute role="manager">
                            <ComingPageLazy />
                        </ProtectedRoute>
                    </Suspense>} />
                    <Route path="train-detail/:trainId" element={<Suspense fallback={<Loading />} >
                        <ProtectedRoute role="manager">
                            <ComingPageLazy />
                        </ProtectedRoute>
                    </Suspense>} />
                </Route>
                <Route path="/login" element={<LoginLazy />} />
                <Route path="/password-remember" element={<PasswordRememberLazy />} />
                <Route path='*' element={<NotFound url="/" status="isAuth" />} />
            </Routes>
        </>
    )
}
export default AppRoutes;