export const types = {
    "tr":{
    "katanerVoltage": "Kataner Voltajı",
    "speed": "Hız",
    "username": "Kullanıcı Adı",
    "password": "Şifre",
    "passwordVerify": "Şifre Tekrarı",
    "name": "Adı",
    "surname": "Soyadı",
    "eMail": "E-mail",
    "phone": "Telefon",
    "role": "Rol",
    "time": "Zaman",
    "actions": "İşlemler",
    "coming": "Yakında Hizmetinizde",
    "headers": {
        "my_information": "Bilgilerim",
        "change_password": "Şifre Değiştir",
        "user_detail": "Kullanıcı Detayı",
        "user_update": "Kullanıcı Güncelle",
        "user_add": "Kullanıcı Ekle",
        "user_list": "Kullanıcı Listesi"
    },
    "filter":{
        "trainName": "Tren Adı",
        "type": {
            "name": "Tipi",
            "catenaryVoltage": "Kataner Voltajı",
            "firstWheelSensorValue": "1.Tekerlek Sensör Değeri",
            "tcfAcceSensorValue": "TCF İvme Sensör Değeri",
            "ExtEnvironmentTemp": "Dış Ortam Sıcaklığı"
        }
    },
    "authority": {
        "name": "Yetki",
        "manager": "Site Yöneticisi",
        "user": "Kullanıcı"
    },
    "sidebar":{
        "homepage": "Ana Sayfa",
        "chart": "Grafik Verileri", 
        "trains": "Trenler",
        "my_information": {
            "name": "Bilgilerim",
            "change_password": "Şifre Değiştir"
        },
        "users": {
            "name": "Kullanıcılar",
            "user_list": "Kullanıcı Listesi",
            "add_user": "Kullanıcı Ekle"
        },
        "log_out": "Çıkış Yap"
    },
    "required": "Bu alan zorunludur.",
    "requiredPassword": "Bu alana zorunludur. Şifreniz 6 karakter ile 15 karakter aralığında olmalıdır. Sadece sayı ve/veya  harflerden oluşmalıdır",
    "placeholders": {
        "phoneInput": "Telefon numaranızı başında 0 olmadan giriniz.",
        "nameInput": "Adınızı giriniz.",
        "surnameInput": "Soyadınızı giriniz.",
        "eMailInput": "E-mail giriniz.",
        "roleInput": "Rol giriniz.",
        "oldPassword": "Eski şifrenizi giriniz",
        "newPassword": "Yeni şifrenizi giriniz",
        "newPasswordVerify": "Yeni şifrenizi tekrar giriniz",
        "password": "Şifre giriniz",
        "passwordVerify": "Şifre tekrarı giriniz"
    },
    "notifications": {
        "user":{
            "updateSuccess": "Güncelleme Başarılı",
            "deleteSuccess": "Silme İşlemi Başarılı",
            "addSuccess":"Ekleme İşlemi Başarılı",
            "updateError": "Güncelleme Başarısız",
            "addError": "Ekleme İşlemi Başarısız",
            "deleteError":"Silme İşlemi Başarısız",
        }
    },
    "buttons": {
        "my_information": "Bilgilerimi Güncelle",
        "user_update": "Kullanıcı Güncelle",
        "user_add": "Kullanıcı Ekle",
        "get_graphic": "Grafiği Güncelle",
        "detail": "Detay",
        "delete": "Sil",
        "update": "Güncelle",
        "log_in": "Giriş Yap",
        "send_password": "Yeni Parolamı Gönder"
    },

    "change-password": {
        "oldPassword": "Eski Şifreniz",
        "newPassword": "Yeni Şifreniz",
        "newPasswordVerify": "Yeni Şifre(Tekrar)"
    },

    "terms": {
        "Catenary Voltage": "Kataner Voltajı",
        "PLC_M00_W1S1": "1.Tekerlek sensör değeri",
        "PLC_M11_BS2": "TCF İvme sensör değeri",
        "PF40_EXT_TEMP": "Dış Ortam Sıcaklığı",
        "table":{
            "catenaryVoltage": "Kataner Voltajı",
            "trainName": "Tren Adı",
            "akbCerReq": "AKB Cer Talebi",
            "time": "Zaman",
            "tcbTotalLoadCurrent": "TCB Toplam Yük Akımı",
            "battCurrent": "Batarya akımı",
            "firstWheelSensorValue": "1. Tekerlek sensör değeri",
            "isParkBrakeActive": "Park Freni"
        }
    },

    "login": {
        "login": "Giriş Yap",
        "remember_password": "Parolamı Hatırlamıyorum",
        "remember_password_header": "Parolamı Hatırla",
        "placeholder": {
            "username": "Lütfen kullanıcı adınızı giriniz!",
            "password": "Lütfen şifrenizi giriniz!"
        }
    },

    "notFound": {
        "isAuth": "Sayfa bulunamadı. 5sn sonra anasayfaya yönlendirileceksiniz.",
        "isNotAuth": "Sayfa bulunamadı. 5sn sonra giriş ekranına yönlendirileceksiniz."
    },

    "footer": "Copyright © 2023 TMS Technic, Tüm Hakları Saklıdır. TMS AR-GE tarafından geliştirildi."
},
"en":{
    "username": "Username",
    "password": "Password",
    "passwordVerify": "Password Again",
    "name": "Name",
    "surname": "Surname",
    "eMail": "E-mail",
    "phone": "Phone",
    "role": "Role",
    "time": "Time",
    "actions": "Operations",
    "coming": "Coming Soon",
    "filter":{
        "trainName": "Train Name",
        "type": {
            "name": "Type",
            "catenaryVoltage": "Catenary Voltage",
            "firstWheelSensorValue": "First Wheel Sensor Value",
            "tcfAcceSensorValue": "TCF Acceleration Sensor Value",
            "ExtEnvironmentTemp": "External Environment Temperature"
        }
    },
    "headers": {
        "my_information": "My Information",
        "change_password": "Change Password",
        "user_detail": "User Detail",
        "user_update": "Update User",
        "user_add": "Add User",
        "user_list": "User List"
    },
    "authority": {
        "name": "Authority",
        "manager": "Site Manager",
        "user": "User"
    },
    "sidebar":{
        "homepage": "Homepage",
        "chart": "Chart Datas", 
        "trains": "Trains",
        "my_information": {
            "name": "My Information",
            "change_password": "Change Password"
        },
        "users": {
            "name": "Users",
            "user_list": "User List",
            "add_user": "Add User"
        },
        "log_out": "Log out"
    },
    "required": "This field is required.",
    "requiredPassword": "This field is required. Your password must be between 6 and 15 characters. Only Numbers and/or letters.",
    "placeholders": {
        "phoneInput": "Enter your phone number",
        "nameInput": "Enter your name.",
        "surnameInput": "Enter your surname.",
        "eMailInput": "Enter your E-mail.",
        "roleInput": "Enter your Role.",
        "oldPassword": "Enter your current password",
        "newPassword": "Enter your new password",
        "newPasswordVerify": "Enter your new password again",
        "password": "Enter your password",
        "passwordVerify": "Enter your password again."
    },
    "notifications": {
        "user":{
            "updateSuccess": "Update Successfull",
            "deleteSuccess": "Delete Successfull",
            "addSuccess":"Add Successfull",
            "updateError": "Update Failed",
            "addError": "Add Failed",
            "deleteError":"Delete Failed",
        }
    },
    "buttons": {
        "my_information": "Update My Information",
        "user_update": "Update User",
        "user_add": "Add User",
        "get_graphic": "Update Graphic",
        "detail": "Detail",
        "delete": "Delete",
        "update":"Update",
        "log_in": "Log in",
        "send_password": "Send my new password"
    },

    "change-password": {
        "oldPassword": "Current Password",
        "newPassword": "New Password",
        "newPasswordVerify": "New Password(Again)"
    },

    "terms": {
        "Catenary Voltage": "Catenary Voltage",
        "PLC_M00_W1S1": "First Wheel Sensor Value",
        "PLC_M11_BS2": "TCF Acceleration Sensor Value",
        "PF40_EXT_TEMP": "External Environment Temperature",
        "table":{
            "catenaryVoltage": "Catenary Voltage",
            "trainName": "Train Name",
            "akbCerReq": "AKB Cer Request",
            "time": "Time",
            "tcbTotalLoadCurrent": "TCB Total Load Current",
            "battCurrent": "Battery Current",
            "firstWheelSensorValue": "First Wheel Sensor Value",
            "isParkBrakeActive": "Park Brake"
        }
    },

    "login": {
        "login": "Log in",
        "remember_password": "I don't remember my password",
        "remember_password_header": "Remember Password",

        "placeholder": {
            "username": "Please enter your username!",
            "password": "Please enter your password!"
        }
    },

    "notFound": {
        "isAuth": "Page is not found. You will be directed homepage after 5 seconds.",
        "isNotAuth": "Page is not found. You will be directed login page after 5 seconds."
    },

    "footer": "Copyright © 2023 TMS Technic, All rights reserved. Developed by TMS R&D."
}
}